import http from '@http'
var request = http.getInstance()
class SolicitationCloud {
	async index() {
		return await request.get("/cloud/pc/index")
	}
	// 通知公告列表 /api/cloud/pc/announcement
	async announcementList(param) {
		return await request.get("/cloud/pc/announcement", param)
	}
	// 详情
	async announcementDetail(param) {
		return await request.get("/cloud/pc/announcement/detail/" + param.id)
	}
	//-------- 招院引所--------
	//入驻院所列表 
	async institutesInfoList(param) {
		return await request.get("/cloud/pc/institutes/info", param)
	}
	// 详情
	async institutesInfoDetail(param) {
		return await request.get("/cloud/pc/institutes/info/detail/" + param.id)
	}
	//招引政策列表
	async institutesPolicyList(param) {
		return await request.get("/cloud/pc/institutes/policy", param)
	}
	// 详情
	async institutesPolicyDetail(param) {
		return await request.get("/cloud/pc/institutes/policy/detail/" + param.id)
	}
	//招引服务(获取一张图片链接)
	async institutesService() {
		return await request.get("/cloud/pc/institutes/service")
	}
	//入驻申请
	async institutesApply(param) {
		return await request.post("/cloud/pc/institutes/apply", param)
	}
	//--------招商引资--------
	// 入驻企业 
	async businessEnterpriseList(param) {
		return await request.get("/cloud/pc/business/enterprise", param)
	}
	// 详情
	async businessEnterpriseDetail(param) {
		return await request.get("/cloud/pc/business/enterprise/detail/" + param.id)
	}
	// 入驻申请
	async businessApply(param) {
		return await request.post("/cloud/pc/business/apply", param)
	}
	// 园区信息 
	async businessParkList(param) {
		return await request.get("/cloud/pc/business/park", param)
	}
	// 详情
	async businessParkDetail(param) {
		return await request.get("/cloud/pc/business/park/detail/" + param.id)
	}
	// 厂房租售 
	async businessWorkshopList(param) {
		return await request.get("/cloud/pc/business/workshop", param)
	}
	// 详情
	async businessWorkshopDetail(param) {
		return await request.get("/cloud/pc/business/workshop/detail/" + param.id)
	}
	// 楼宇租售 
	async businessBuildingList(param) {
		return await request.get("/cloud/pc/business/building", param)
	}
	// 详情
	async businessBuildingDetail(param) {
		return await request.get("/cloud/pc/business/building/detail/" + param.id)
	}
	// 招引案例  
	async businessExampleList(param) {
		return await request.get("/cloud/pc/business/example", param)
	}
	// 详情
	async businessExampleDetail(param) {
		return await request.get("/cloud/pc/business/example/detail/" + param.id)
	}
	// 招引政策 
	async businessPolicyList(param) {
		return await request.get("/cloud/pc/business/policy", param)
	}
	// 详情
	async businessPolicyDetail(param) {
		return await request.get("/cloud/pc/business/policy/detail/" + param.id)
	}
	//-----------招才引智-----------
	// 人才招聘
	async talentRecruitList(param) {
		return await request.get("/cloud/pc/talent/recruit", param)
	}
	// 详情
	async talentRecruitDetail(param) {
		return await request.get("/cloud/pc/talent/recruit/detail/" + param.id)
	}
	// 招才政策
	async talentPolicyitList(param) {
		return await request.get("/cloud/pc/talent/policy", param)
	}
	// 详情
	async talentPolicyDetail(param) {
		return await request.get("/cloud/pc/talent/policy/detail/" + param.id)
	}
	//-----------院士专家小镇-----------
	//  小镇展示
	async expertTownList(param) {
		return await request.get("/cloud/pc/expert/town", param)
	}
	// 详情
	async expertTownDetail(param) {
		return await request.get("/cloud/pc/expert/town/detail/" + param.id)
	}
	// 院士专家介绍
	async expertList(param) {
		return await request.get("/cloud/pc/expert", param)
	}
	//详情
	async expertDetail(param) {
		return await request.get("/cloud/pc/expert/detail/" + param.id)
	}
	//  院士大讲堂 
	async expertForumList(param) {
		return await request.get("/cloud/pc/expert/forum", param)
	}
	// 详情
	async expertForumDetail(param) {
		return await request.get("/cloud/pc/expert/forum/detail/" + param.id)
	}
	//  政策支持
	async expertPolicyList(param) {
		return await request.get("/cloud/pc/expert/policy", param)
	}
	// 详情
	async expertPolicyDetail(param) {
		return await request.get("/cloud/pc/expert/policy/detail/" + param.id)
	}
	//  入驻申请
	async expertApply(param) {
		return await request.post("/cloud/pc/expert/apply", param)
	}
	//-----------基金小镇-----------
	//  小镇展示
	async fundTownList(param) {
		return await request.get("/cloud/pc/fund/town", param)
	}
	// 详情
	async fundTownDetail(param) {
		return await request.get("/cloud/pc/fund/town/detail/" + param.id)
	}
	// 金融机构
	async fundGizList(param) {
		return await request.get("/cloud/pc/fund/giz", param)
	}
	// 详情
	async fundGizDetail(param) {
		return await request.get("/cloud/pc/fund/giz/detail/" + param.id)
	}
	// 路演活动
	async fundActivityList(param) {
		return await request.get("/cloud/pc/fund/activity", param)
	}
	// 详情
	async fundActivityDetail(param) {
		return await request.get("/cloud/pc/fund/activity/detail/" + param.id)
	}
	// 政策支持 
	async fundPolicyList(param) {
		return await request.get("/cloud/pc/fund/policy", param)
	}
	// 详情
	async fundPolicyDetail(param) {
		return await request.get("/cloud/pc/fund/policy/detail/" + param.id)
	}
	// 入驻申请 
	async fundApply() {
		return await request.post("/cloud/pc/fund/apply")
	}
	// 活动报名 
	async activityApply(param) {
		return await request.post("/cloud/fund/activity/apply", param)
	}
}
export default new SolicitationCloud()
