import http from '@http'
var request = http.getInstance()
class PolicyHall {
	async index() {
		return await request.get("/policy/pc/index")
	}
	// 动态列表
	async informationList(param) {
		return await request.get("/policy/pc/information", param)
	}
	// 动态详情
	async informationDetail(param) {
		return await request.get("/policy/pc/information/detail/" + param.id)
	}
	// 政策、解读、科技列表
	async infoList(param) {
		return await request.get("/policy/pc/info", param)
	}
	// 政策、解读、科技详情
	async infoDetail(param) {
		return await request.get("/policy/pc/info/detail/" + param.id)
	}
}
export default new PolicyHall()
