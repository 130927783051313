import axios from 'axios'
import config from '@config'
import storage from '@/utils/storage.js'
import {
	Message,
	MessageBox
} from 'element-ui'
import router from '@/router'
import store from '@/store'
import util from '@/utils'
import app from '@/main'

class Http {
	static instance;

	// 单例模式
	static getInstance() {
		if (this.instance instanceof this) {
			return this.instance;
		} else {
			this.instance = new this;
			return this.instance;
		}
	}

	constructor() {
		this.interceptors();
	}
	async get(url, params = {}, header = {}, responseType = '') {
		axios.defaults.baseURL = config.check();
		return await axios.get(url, {
			params: params,
			responseType: responseType,
			headers: Object.assign(
				header, {
					authorization: storage.getToken()
				}
			)
		})
	}

	async post(url, params = {}, header = {}) {
		axios.defaults.baseURL = config.check();
		return await axios.post(url, params, {
			headers: Object.assign(
				header, {
					authorization: storage.getToken()
				}
			)
		})
	}

	async put(url, params = {}, header = {}) {
		axios.defaults.baseURL = config.check();
		return await axios.put(url, params, {
			headers: Object.assign(
				header, {
					authorization: storage.getToken()
				}
			)
		})
	}

	async delete(url, params = {}, header = {}) {
		axios.defaults.baseURL = config.check();
		return await axios.delete(url, {
			params: params,
			responseType: responseType,
			headers: Object.assign(
				header, {
					authorization: storage.getToken()
				}
			)
		})
	}

	interceptors() {
		/**
		 * 请求拦截器
		 */
		axios.interceptors.request.use((config) => {
			const ctLocale = store.state.ctLocale
			if (config.method == 'get') {
				if (ctLocale) {
					config.params['temp_lang'] = ctLocale
				}
				// config.params['device_name'] = 'PC'
				// const auth_key = util.signParam(config.params)
				// config.params['sign'] = auth_key
			} else {
				// post
				if (ctLocale) {
					config.data['temp_lang'] = ctLocale
				}
				// config.data['device_name'] = 'PC'
				// const auth_key = util.signParam(config.data)
				// config.data['sign'] = auth_key
			}

			return config;
		}, (error) => {
			// 对请求错误做些什么
			return Promise.reject(error);
		});

		/**
		 * 响应拦截器 
		 * 在这里处理返回数据的状态码
		 */
		axios.interceptors.response.use(async (res) => {
				// res.setHeader('Access-Control-Allow-Origin', '*')
				const message = res.data.msg || '未知错误'
				if (res.data.code == 200) {
					return res;
				}
				if (res.data.code == 401) {
					MessageBox.alert('请先登录', '提示', {
						confirmButtonText: '确定',
						callback: action => {
							console.log(router)
							if (action=='confirm'){
								if (router?.history?.current.name != 'Logon') {
									router.push('/logon')
								}
							}
						}
					});
					return res;
				}
				// 重新登录逻辑MessageBox
				if (res.data.code == 100) {
					storage.setToken(null)
					storage.setUserInfo(null)
					if (router?.history?.current.name != 'Logon') {
						router.push('/logon')
					}
					return res;
				}
				if (store.state.currentToastMessage != message) {
					store.state.currentToastMessage = message
					Message({
						message: message,
						type: 'error',
						onClose: () => {
							store.state.currentToastMessage = null
						}
					})
				}
				// this.toastErr(message)
				return Promise.reject(res);

			},
			function(error) {
				const message = `${error}`
				if (store.state.currentToastMessage != message) {
					store.state.currentToastMessage = message
					Message({
						message: message,
						type: 'error',
						onClose: () => {
							store.state.currentToastMessage = null
						}
					})
				}
				return Promise.reject(error);
			});
	}

	/**
	 * 设置请求基地址
	 */
	SetBaseUrl() {
		axios.defaults.baseURL = config.check();
	}

}

export default Http
