import http from '@http'
var request = http.getInstance()
class DigitalServices {
	async index() {
		return await request.get("/ds/pc/index")
	}
	// 通知公告
	async announcementList(param) {
		return await request.get("/ds/pc/announcement", param)
	}
	// 通知公告详情
	async announcementDetail(param) {
		return await request.get("/ds/pc/announcement/detail/" + param.id)
	}
	// 环境试验
	async environmentList(param) {
		return await request.get("/ds/pc/environment", param)
	}
	// 环境试验详情
	async environmentDetail(param) {
		return await request.get("/ds/pc/environment/detail/" + param.id)
	}
	// 并行/仿真计算/api/ds/pc/calculation
	async calculationList(param) {
		return await request.get("/ds/pc/calculation", param)
	}
	// 并行/仿真计算详情
	async calculationDetail(param) {
		return await request.get("/ds/pc/calculation/detail/" + param.id)
	}
	// 算力服务
	async csList(param) {
		return await request.get("/ds/pc/cs", param)
	}
	// 算力服务详情
	async csDetail(param) {
		return await request.get("/ds/pc/cs/detail/" + param.id)
	}
	// 服务申请 
	async apply(param) {
		return await request.post("/ds/apply", param)
	}
	// 搜索
	async searchList(param) {
		return await request.get("/ds/pc/search", param)
	}
}
export default new DigitalServices()
