<template>
  <div class="footer">
    <div class="left">
      <!-- <img src="../static/images/logo1@2x.png" class="logo"/> -->
      <div class="name">
        <!-- <div class="h2 xieti">云上科技城</div> -->
        <img src="../static/images/name@2x.png" class="name-yun" />
        <div class="h4">中国 · 绵阳</div>
      </div>
    </div>
    <div class="right h4" style="padding-top: 30px;">
      <div>
        <span style="font-weight: 700;">更多链接：</span>
        <span style="margin:0 20px;"><a class="link" href="https://www.most.gov.cn/">科学技术部</a></span>
        <span style="margin:0 20px;"><a class="link" href="https://kjt.sc.gov.cn/">四川省科学技术厅</a></span>
        <span style="margin:0 20px;"><a class="link" href="http://kjj.my.gov.cn">绵阳市科学技术局</a></span>
      </div>

      <div>
        <span style="font-weight: 700;">合作平台：</span>
        <span style="margin:0 20px;"><a class="link"
            href="https://fuwu.most.gov.cn/html/zxbl/?search=%E7%A7%91%E6%8A%80%E5%9E%8B%E4%B8%AD%E5%B0%8F%E4%BC%81%E4%B8%9A%E8%AF%84%E4%BB%B7">科技型中小企业评价平台</a></span>
        <span style="margin:0 20px;"><a class="link" href="http://202.61.89.120">四川省科技管理信息系统</a></span>
        <span style="margin:0 20px;"><a class="link" href="https://mianyang.tccxfw.com">绵阳市科技项目综合管理平台</a></span>
      </div>
      <div>
        <span style="font-weight: 700;">运营单位：</span>
        <span style="margin:0 20px;"><a class="link" href="https://www.ctex.cn">中国技术交易所</a></span>
        <span style="margin:0 20px;"><a class="link" href="https://www.kjcrcw.com">绵阳科技城人才发展集团有限责任公司</a></span>
      </div>
      <a class="link" href="https://beian.miit.gov.cn/">备案号： 蜀ICP备2022001611号-2</a>
      <br />

      <!-- <span>版权所有@2022｜绵阳新投实业有限公司</span> -->
      <br />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  components: {},
  data() {
    return {
    }
  },
  computed: {

  },
  created() {

  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.footer {
  margin: 0 auto;
  padding: 60px 0 140px;
  display: flex;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  line-height: 1.4;
  background-image: url("../static/images/footer.png");
  background-size: 100% 100%;
  background-position: 0 bottom;

  .left {
    display: flex;
    align-items: center;

    .logo {
      width: 117px;
      height: 46px;
    }

    .name {
      text-align: center;
      padding-left: 10px;
      padding-right: 20px;
      border-right: 2px solid #fff;
      margin-right: 5px;

      .h2 {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
      }

      .h4 {
        font-size: 16px;
        text-align: right;
      }

      .name-yun {
        width: 140px;
        height: auto;
      }
    }
  }

  .right {
    padding-left: 20px;

    .link {
      line-height: 30px;
      color: #fff;
    }
  }
}
</style>
