/**
 * 英语
 */
 export default {
  // 菜单路由
  route: {
    home: '首页',
    notice: '公告'
  },
  // 主框架
  layout: {
    download: '下载',
    more: '更多'
  },
  // 基础列表
  list: {
    
  },
  home: {
    slogan: 'slogan',
    slogan_desc: 'slogan_desc'
  }
};
