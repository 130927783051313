import http from '@http'
let request = http.getInstance()
class TechnologyFinance {
	async index() {
		return await request.get("/finance/pc/index")
	}
	// 通知公告列表 /api/finance/pc/announcement
	async announcementList(param) {
		return await request.get("/finance/pc/announcement", param)
	}
	// 公告详情
	async announcementDetail(id) {
		return await request.get(`/finance/pc/announcement/detail/${id}`)
	}
	//找贷款列表 /api/finance/pc/loan
	async loanList(param) {
		return await request.get("/finance/pc/loan", param)
	}
	// 详情
	async loanDetail(id) {
		return await request.get(`/finance/pc/loan/detail/${id}`)
	}
	//找补贴列表 /api/finance/pc/subsidy
	async subsidyList(param) {
		return await request.get("/finance/pc/subsidy", param)
	}
	// 详情 /api/finance/pc/subsidy/detail/{id}
	async subsidyDetail(id) {
		return await request.get(`/finance/pc/subsidy/detail/${id}`)
	}
	// 找风投 /api/finance/pc/investment
	async investmentList(param) {
		return await request.get("/finance/pc/investment", param)
	}
	// 详情
	async investmentDetail(id) {
		return await request.get(`/finance/pc/investment/detail/${id}`)
	}
	// 找保险 /api/finance/pc/insurance
	async insuranceList(param) {
		return await request.get("/finance/pc/insurance", param)
	}
	// 详情
	async insuranceDetail(id) {
		return await request.get(`/finance/pc/insurance/detail/${id}`)
	}
	// 找活动 /api/finance/pc/activity
	async activityList(param) {
		return await request.get("/finance/pc/activity", param)
	}
	// 详情
	async activityDetail(id) {
		return await request.get(`/finance/pc/activity/detail/${id}`)
	}
	// 找活动 /api/finance/pc/project
	async projectList(param) {
		return await request.get("/finance/pc/project", param)
	}
	// 详情
	async projectDetail(id) {
		return await request.get(`/finance/pc/project/detail/${id}`)
	}
	// 找政策 /api/finance/pc/policy
	async policyList(param) {
		return await request.get("/finance/pc/policy", param)
	}
	// 详情
	async policyDetail(id) {
		return await request.get(`/finance/pc/policy/detail/${id}`)
	}
	// 详情
	async institution() {
		return await request.get(`/finance/pc/loan/institution`)
	}
}
export default new TechnologyFinance()
