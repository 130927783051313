import http from '@http'
var request = http.getInstance()
// async 暴露出去的自定义请求名 (params) {
//     return await request.请求方式(
//         接口地址,
//         参数,
//         请求头,
//         响应类型
//     )
// }
class Share {
	// 首页
	async index(params) {
		return await request.get("/share/pc/index", params)
	}
	// 通知公告
	async announcement(params) {
		return await request.get("/share/pc/announcement", params)
	}
	// 公告详情 
	async announcementDetail(id) {
		return await request.get(`/share/pc/announcement/detail/${id}`)
	}
	// 大科学装置列表
	async device(params) {
		return await request.get("/share/pc/device", params)
	}
	// 大科学装置详情
	async deviceDetail(id) {
		return await request.get(`/share/pc/device/detail/${id}`)
	}
	// 大型仪器列表
	async instrument(params) {
		return await request.get("/share/pc/instrument", params)
	}
	// 大型仪器详情
	async instrumentDetail(id) {
		return await request.get(`/share/pc/instrument/detail/${id}`)
	}
	// 仪器商城列表 
	async market(params) {
		return await request.get("/share/pc/market", params)
	}
	// 仪器商城详情 
	async marketDetail(id) {
		return await request.get(`/share/pc/market/detail/${id}`)
	}
	// 仪器研发列表 
	async rd(params) {
		return await request.get("/share/pc/rd", params)
	}
	// 仪器商城详情 
	async rdDetail(id) {
		return await request.get(`/share/pc/rd/detail/${id}`)
	}
	// 共建单位列表 
	async unit(params) {
		return await request.get("/share/pc/unit", params)
	}
	// 共建单位详情 
	async unitDetail(id) {
		return await request.get(`/share/pc/unit/detail/${id}`)
	}
	async getInstrumentList(params) {
		return await request.get("/share/pc/getInstrumentList", params)
	}
	async search(params) {
		return await request.get("/share/pc/search", params)
	}
	async send(params) {
		return await request.post("/share/publishNeeds", params)
	}
	async join(params) {
		return await request.post("/share/attack", params)
	}
	async appointment(params) {
		return await request.post("/share/appointment", params)
	}
	// 下载专区
	async filesList(params) {
		return await request.get("/share/pc/download", params)
	}
}
export default new Share()
