<template>
  <div id="app" class="main-box">
    <div>
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>
<script>

export default {
  name: 'App',
  components: {

  },
  data() {
    return {

    }
  },
  computed: {},
  watch: {
    '$route'(to, from) {
      document.getElementById('app').scrollTop = 0;
    }
  },
  created() {


  },
  mounted() {
    // console.log('this.$api===', this.$api)
  },
  methods: {}
}
</script>

<style lang="less">
#app {
  height: 100vh;
  overflow: auto;
}
</style>
