export default {
	"getUserInfo": function () {
		return JSON.parse(sessionStorage.getItem('userInfo')) || {}
	},
	"setUserInfo": function (val) {
		sessionStorage.setItem('userInfo', JSON.stringify(val))
	},
	"getToken": function () {
		return JSON.parse(sessionStorage.getItem('token')) || null
	},
	"setToken": function (val) {
		sessionStorage.setItem('token', JSON.stringify(val))
	}
}
