import Layout from '@/views/layout/index.vue'
import Second from '@/views/layout/second.vue'
import Third from '@/views/layout/third.vue'
const clouds = [{
	path: '/digitalServices',
	name: 'DigitalServices',
	component: Second,
	redirect: '/digitalServices/index',
	meta: {
		'title': '数字服务'
	},
	children: [{
		path: 'index',
		name: 'index',
		meta: {
			'title': '数字服务'
		},
		component: () => import('../views/digitalServices/index.vue'),
	},
	{
		path: 'page-search',
		name: 'page-search',
		meta: {
			'title': '搜索'
		},
		component: () => import('../views/digitalServices/page-search.vue'),
	},
	{
		path: 'page-notice',
		name: 'page-notice',
		meta: {
			'title': '通知公告'
		},
		component: () => import('../views/digitalServices/page-notice.vue'),
	},
	{
		path: 'page-notice',
		name: 'page-notice',
		meta: {
			title: '通知公告'
		},
		component: Third,
		redirect: '/digitalServices/page-notice/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '通知公告'
			},
			component: () => import('../views/digitalServices/page-notice.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '公告详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		]
	},
	{
		path: 'page-hjsy',
		name: 'page-hjsy',
		meta: {
			'title': '环境试验'
		},
		component: () => import('../views/digitalServices/page-hjsy.vue')
	},
	{
		path: 'page-hjsy',
		name: 'page-hjsy',
		meta: {
			title: '环境试验'
		},
		component: Third,
		redirect: '/digitalServices/page-hjsy/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '环境试验'
			},
			component: () => import('../views/digitalServices/page-hjsy.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '详情'
			},
			component: () => import('../views/digitalServices/page-detail.vue')
		},
		{
			path: 'applyservice',
			name: 'applyservice',
			meta: {
				'title': '申请服务'
			},
			component: () => import('../views/digitalServices/page-applyservice.vue')
		},
		]
	},
	{
		path: 'page-slfw',
		name: 'page-slfw',
		meta: {
			'title': '算力服务'
		},
		component: () => import('../views/digitalServices/page-slfw.vue')
	},
	{
		path: 'page-slfw',
		name: 'page-slfw',
		meta: {
			title: '算力服务'
		},
		component: Third,
		redirect: '/digitalServices/page-slfw/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '算力服务'
			},
			component: () => import('../views/digitalServices/page-slfw.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '算力服务'
			},
			component: () => import('../views/digitalServices/page-detail.vue')
		},
		{
			path: 'applyservice',
			name: 'applyservice',
			meta: {
				'title': '申请服务'
			},
			component: () => import('../views/digitalServices/page-applyservice.vue')
		},

		]
	},
	{
		path: 'page-js',
		name: 'page-js',
		meta: {
			'title': '并行/仿真计算'
		},
		component: () => import('../views/digitalServices/page-js.vue')
	},
	{
		path: 'page-js',
		name: 'page-js',
		meta: {
			title: '并行/仿真计算'
		},
		component: Third,
		redirect: '/digitalServices/page-js/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '并行/仿真计算'
			},
			component: () => import('../views/digitalServices/page-js.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '详情'
			},
			component: () => import('../views/digitalServices/page-detail.vue')
		},
		{
			path: 'applyservice',
			name: 'applyservice',
			meta: {
				'title': '申请服务'
			},
			component: () => import('../views/digitalServices/page-applyservice.vue')
		},
		]
	},
	]
},
{
	path: '/solicitationCloud',
	name: 'solicitationCloud',
	component: Second,
	redirect: '/solicitationCloud/index',
	meta: {
		'title': '云上招引'
	},
	children: [{
		path: 'index',
		name: 'index',
		meta: {
			'title': '云上招引'
		},
		component: () => import('../views/solicitationCloud/index.vue')
	},
	{
		path: 'page-huodongbaoming',
		name: 'page-huodongbaoming',
		meta: {
			'title': '活动报名'
		},
		component: () => import('../views/solicitationCloud/page-huodongbaoming.vue')
	},
	{
		path: 'page-zyys',
		name: 'page-zyys',
		meta: {
			'title': '招院引所'
		},
		component: () => import('../views/solicitationCloud/page-zyys.vue')
	},
	{
		path: 'page-zyys',
		name: 'page-zyys',
		meta: {
			title: '招院引所'
		},
		component: Third,
		redirect: '/solicitationCloud/page-zyys/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '招院引所'
			},
			component: () => import('../views/solicitationCloud/page-zyys.vue')
		},
		{
			path: 'yuansuoDetail',
			name: 'yuansuoDetail',
			meta: {
				'title': '院所详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '政策详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		]
	},
	{
		path: 'page-zsyz',
		name: 'page-zsyz',
		meta: {
			'title': '招商引资'
		},
		component: () => import('../views/solicitationCloud/page-zsyz.vue')
	},
	{
		path: 'page-zsyz',
		name: 'page-zsyz',
		meta: {
			title: '招商引资'
		},
		component: Third,
		redirect: '/solicitationCloud/page-zsyz/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '招商引资'
			},
			component: () => import('@/views/solicitationCloud/page-zsyz.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '政策详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		{
			path: 'detail-ruzhuqiye',
			name: 'detail-ruzhuqiye',
			meta: {
				'title': '入驻企业'
			},
			component: () => import('@/views/solicitationCloud/detail-ruzhuqiye.vue')
		},
		{
			path: 'detail-yuanquxinxi',
			name: 'detail-yuanquxinxi',
			meta: {
				'title': '园区信息'
			},
			component: () => import('@/views/solicitationCloud/detail-yuanquxinxi.vue')
		},
		{
			path: 'detail-louyuzushou',
			name: 'detail-louyuzushou',
			meta: {
				'title': '楼宇租售'
			},
			component: () => import('@/views/solicitationCloud/detail-louyuzushou.vue')
		},
		{
			path: 'detail-changfang',
			name: 'detail-changfang',
			meta: {
				'title': '厂房租售'
			},
			component: () => import('@/views/solicitationCloud/detail-changfang.vue')
		},
		{
			path: 'anliDetail',
			name: 'anliDetail',
			meta: {
				'title': '案例详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		]
	},

	{
		path: 'page-zcyz',
		name: 'page-zcyz',
		meta: {
			'title': '招才引智'
		},
		component: () => import('../views/solicitationCloud/page-zcyz.vue')
	},
	{
		path: 'page-zcyz',
		name: 'page-zcyz',
		meta: {
			title: '招才引智'
		},
		component: Third,
		redirect: '/solicitationCloud/page-zcyz/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '招才引智'
			},
			component: () => import('../views/solicitationCloud/page-zcyz.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '政策详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		{
			path: 'detail-rencaizhaopin',
			name: 'detail-rencaizhaopin',
			meta: {
				'title': '人才招聘'
			},
			component: () => import('@/views/solicitationCloud/detail-rencaizhaopin.vue')
		},
		]
	},

	{
		path: 'page-ysxz',
		name: 'page-ysxz',
		meta: {
			'title': '院士专家小镇'
		},
		component: () => import('../views/solicitationCloud/page-ysxz.vue')
	},
	{
		path: 'page-ysxz',
		name: 'page-ysxz',
		meta: {
			title: '院士专家小镇'
		},
		component: Third,
		redirect: '/solicitationCloud/page-ysxz/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '院士专家小镇'
			},
			component: () => import('../views/solicitationCloud/page-ysxz.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '政策详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		{
			path: 'detail-yuanshizhuanjia',
			name: 'detail-yuanshizhuanjia',
			meta: {
				'title': '院士介绍'
			},
			component: () => import('@/views/solicitationCloud/detail-yuanshizhuanjia.vue')
		},
		{
			path: 'detail-yuanshijiangtang',
			name: 'detail-yuanshijiangtang',
			meta: {
				'title': '院士大讲堂'
			},
			component: () => import('@/views/solicitationCloud/detail-yuanshijiangtang.vue')
		},
		]
	},
	{
		path: 'page-jjxz',
		name: 'page-jjxz',
		meta: {
			'title': '基金小镇'
		},
		component: () => import('../views/solicitationCloud/page-jjxz.vue')
	},
	{
		path: 'page-jjxz',
		name: 'page-jjxz',
		meta: {
			title: '基金小镇'
		},
		component: Third,
		redirect: '/solicitationCloud/page-jjxz/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '基金小镇'
			},
			component: () => import('../views/solicitationCloud/page-jjxz.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '政策详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		{
			path: 'detail-jinrongjigou',
			name: 'detail-jinrongjigou',
			meta: {
				'title': '金融机构'
			},
			component: () => import('../views/solicitationCloud/detail-jinrongjigou.vue')
		},
		{
			path: 'detail-huodong',
			name: 'detail-huodong',
			meta: {
				'title': '路演活动'
			},
			component: () => import('../views/solicitationCloud/detail-huodong.vue')
		},
		]
	},
	{
		path: 'page-tzgg',
		name: 'page-tzgg',
		meta: {
			'title': '通知公告'
		},
		component: () => import('../views/solicitationCloud/page-tzgg.vue')
	},
	{
		path: 'page-tzgg',
		name: 'page-tzgg',
		meta: {
			title: '通知公告'
		},
		component: Third,
		redirect: '/solicitationCloud/page-tzgg/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '通知公告'
			},
			component: () => import('../views/solicitationCloud/page-tzgg.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '公告详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		]
	},

	]
},
{
	path: '/technologyExpoCloud',
	name: 'TechnologyExpoCloud',
	component: () => import('../views/technologyExpoCloud/index.vue')
},
{
	path: '/policyHall',
	name: 'PolicyHall',
	component: Second,
	redirect: '/policyHall/index',
	meta: {
		'title': '政策大厅'
	},
	children: [{
		path: 'index',
		name: 'index',
		meta: {
			'title': '政策大厅'
		},
		component: () => import('../views/policyHall/index.vue'),
	},
	{
		path: 'page-dongtai',
		name: 'page-dongtai',
		meta: {
			'title': '动态信息'
		},
		component: () => import('../views/policyHall/page-dongtai.vue'),
	},
	{
		path: 'page-dongtai',
		name: 'page-dongtai',
		meta: {
			title: '动态信息'
		},
		component: Third,
		redirect: '/policyHall/page-dongtai/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '动态信息'
			},
			component: () => import('../views/policyHall/page-dongtai.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '动态信息详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		]
	},
	{
		path: 'page-guojia',
		name: 'page-guojia',
		meta: {
			'title': '国家政策'
		},
		component: () => import('../views/policyHall/page-guojia.vue'),
	},
	{
		path: 'page-search',
		name: 'page-search',
		meta: {
			'title': '搜索'
		},
		component: () => import('../views/policyHall/page-search.vue'),
	},
	{
		path: 'page-guojia',
		name: 'page-guojia',
		meta: {
			title: '国家政策'
		},
		component: Third,
		redirect: '/policyHall/page-guojia/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '国家政策'
			},
			component: () => import('../views/policyHall/page-guojia.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '政策详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		]
	},
	{
		path: 'page-sheng',
		name: 'page-sheng',
		meta: {
			'title': '四川省级政策'
		},
		component: () => import('../views/policyHall/page-sheng.vue'),
	},
	{
		path: 'page-sheng',
		name: 'page-sheng',
		meta: {
			title: '省级政策'
		},
		component: Third,
		redirect: '/policyHall/page-sheng/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '四川省级政策'
			},
			component: () => import('../views/policyHall/page-sheng.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '四川政策详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		]
	},
	{
		path: 'page-shi',
		name: 'page-shi',
		meta: {
			'title': '绵阳市级政策'
		},
		component: () => import('../views/policyHall/page-shi.vue'),
	},
	{
		path: 'page-shi',
		name: 'page-shi',
		meta: {
			title: '绵阳市级政策'
		},
		component: Third,
		redirect: '/policyHall/page-shi/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '绵阳市级政策'
			},
			component: () => import('../views/policyHall/page-shi.vue')
		},
		{
			path: 'articledetail',
			name: 'articledetail',
			meta: {
				'title': '政策详情'
			},
			component: () => import('@/components/page-articledetail.vue')
		},
		]
	},

	]
}
]

export default clouds
