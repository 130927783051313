import Common from './common.js';
import policyHall from './policyHall.js';
import digitalServices from './digitalServices.js';
import solicitationCloud from './solicitationCloud.js';
import technologyFinance from './tecknologyFinance.js';
import intellectualProperty from './intellectualProperty.js';
import Share from './share.js';
export default {
	common: 						Common,
	policyHall: 				policyHall,
	digitalServices: 		digitalServices,
	solicitationCloud:	solicitationCloud,
	share: 							Share,
	technologyFinance:	technologyFinance,
	intellectualProperty:intellectualProperty,
}
