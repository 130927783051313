/**
 * 国际化配置
 */
import Vue from 'vue'
import zh_CN from './lang/zh_CN';
import en from './lang/en';
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)
const i18n =  new VueI18n({
	  globalInjection: true, // 全局注册 $t方法
	  locale: localStorage.getItem('lang') || 'zh_CN',
	  messages: {
      zh_CN,
      en
    }
});
export default i18n;
