import http from '@http'
var request = http.getInstance()
// async 暴露出去的自定义请求名 (params) {
//     return await request.请求方式(
//         接口地址,
//         参数,
//         请求头,
//         响应类型
//     )
// }
class Common {
	// 获取验证码
	async captchaImage(params) {
		return await request.get("/captchaImage", params)
	}
	// 登录
	async login(params) {
		return await request.post(`/user/pc/login?username=${params.username}&password=${params.password}`, params)
	}
	// 注册
	async register(params) {
		return await request.post("/user/pc/register", params)
	}
	// 注册
	async home(params) {
		return await request.get("/index/pc/index", params)
	}
	async more(params) { // api/index/pc/more
		return await request.get("/index/pc/more", params)
	}
	async order(params) {
		return await request.get("/user/orderRecord", params)
	}
	async userUpdate(params) {
		return await request.post("/user/update", params)
	}
	async userPassword(params) {
		return await request.post("/user/updatePassword", params)
	}
	async getUserInfo(params) {
		return await request.get("/user/getUserInfo", params)
	}
}
export default new Common()

