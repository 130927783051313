<template>
  <div class="bread-nav">
    <span>当前位置<i class="el-icon-location-outline"></i> </span>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="(item, index) in lists" :key="index">
        <router-link :to="item.path">{{item.meta.title}}</router-link>
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
	export default {
		name: 'Bread',
		components: {},
		data() {
			return {
        lists: [] 
			}
		},
		computed: {

		},
    watch:{
      $route(to,from)  {
        console.log(to)
        this.lists = to.matched
      }
    },
		created() {
      this.lists = this.$route.matched
		},
		methods: {

		}
	}
</script>

<style scoped lang="less">
</style>

