import Vue from 'vue'
import Vuex from 'vuex'
import storage from '@/utils/storage.js'
import that from '@/main.js'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		// 用户信息
		user_info: storage.getUserInfo(),
		// 当前弹窗内容（避免同样的内容短时间内重复弹出）
		currentToastMessage: ''
	},
	mutations: {
		//更新用户信息
		updateUserInfo(state, info) {
			console.log('info = ', info)
			if(info === 'clear') {
				state.user_info = {}
				storage.setUserInfo({})
			} else {
				state.user_info = Object.assign(state.user_info ?? {}, info)
				storage.setUserInfo(info)
			}
			console.log(state.user_info)
		},
		updateToken(state, info) {
			state.token = info
			storage.setToken(info)
		}
	},
	actions: {},
	modules: {},
	getters: {
		user_info(state) {
			return state.user_info
		},
		share_current() {
			return state.share_current
		}
	},
})
