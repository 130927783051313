<template>
  <div id="keep" class="app">
    <header-nav id="nav"></header-nav>
    <div>
      <router-view />
    </div>
    <my-footer></my-footer>
  </div>
</template>
<script>
import HeaderNav from "@/components/header-nav.vue";
import MyFooter from "@/components/footer.vue"
export default {
  name: 'Layout',
  components: {
    HeaderNav,
    MyFooter
  },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.app {
  min-width: 1280px;
  background-color: #0031bd;
  // background-color: #333;
  min-height: 100vh;
  height: auto;
  // background-image: url('../../static/images/bg.png');
  background-image: url("../../static/index-bg.png");
  background-size: 100% 80%;
  background-position: 0 750px;
  background-repeat: no-repeat;
}
</style>