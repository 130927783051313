import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout/index.vue'
import Second from '@/views/layout/second.vue'
import Third from '@/views/layout/third.vue'
import clouds from './clouds.js'
Vue.use(VueRouter)

const routes = [{
	path: '/',
	name: 'Home',
	desc: '序',
	component: Layout,
	redirect: '/boot',
	children: [{
		path: '/home',
		name: 'Home',
		desc: '首页',
		component: () => import('../views/Home.vue')
	},
	{
		path: '/technologyMarket',
		name: 'TechnologyMarket',
		desc: '科技大市场',
		component: () => import('../views/technologyMarket/index.vue')
	},
	{
		path: '/sharedInstrument',
		name: 'SharedInstrument',
		meta: {
			title: '仪器共享'
		},
		component: Second,
		redirect: '/sharedInstrument/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '仪器共享'
			},
			component: () => import('../views/sharedInstrument/index.vue')
		},
		{
			path: 'instrumentDetail',
			name: 'instrumentDetail',
			meta: {
				title: '仪器装置设备详情'// '仪器详情'
			},
			component: () => import('../views/sharedInstrument/instrumentDetail.vue')
		},
		{
			path: 'fDetail',
			name: 'fDetail',
			meta: {
				title: '共建单位详情'// '仪器详情'
			},
			component: () => import('../views/sharedInstrument/fDetail.vue')
		},
		{
			path: 'market',
			name: 'market',
			meta: {
				title: '仪器商城'
			},
			component: () => import('../views/sharedInstrument/market.vue')
		},
		{
			path: 'equipment',
			name: 'equipment',
			meta: {
				title: '大科学装置共享'
			},
			component: () => import('../views/sharedInstrument/equipment.vue')
		},
		{
			path: 'instrument',
			name: 'instrument',
			meta: {
				title: '大型仪器共享'
			},
			component: () => import('../views/sharedInstrument/instrument.vue')
		},
		{
			path: 'yiqiyanfa',
			name: 'yiqiyanfa',
			meta: {
				title: '仪器研发'
			},
			component: () => import('../views/sharedInstrument/yiqiyanfa.vue')
		},
		{
			path: 'subscribe',
			name: 'subscribe',
			meta: {
				title: '立即预约'
			},
			component: () => import('../views/sharedInstrument/subscribe.vue')
		},
		{
			path: 'send',
			name: 'send',
			meta: {
				title: '发布需求'
			},
			component: () => import('../views/sharedInstrument/send.vue')
		},
		{
			path: 'join',
			name: 'join',
			meta: {
				title: '参与攻关'
			},
			component: () => import('../views/sharedInstrument/join.vue')
		},
		{
			path: 'search',
			name: 'search',
			meta: {
				title: '搜索'
			},
			component: () => import('../views/sharedInstrument/search.vue')
		},
		{
			path: 'development',
			name: 'development',
			meta: {
				title: '仪器研发'
			},
			component: () => import('../views/sharedInstrument/development.vue')
		},
		{
			path: 'friends',
			name: 'friends',
			meta: {
				title: '共建单位'
			},
			component: () => import('../views/sharedInstrument/friends.vue')
		},
		{
			path: 'notice',
			name: 'notice',
			meta: {
				title: '通知公告'
			},
			component: () => import('../views/sharedInstrument/notice.vue')
		},
		{
			path: 'notice',
			name: 'notice',
			meta: {
				title: '通知公告'
			},
			component: Third,
			redirect: '/sharedInstrument/notice/index',
			children: [
				{
					path: 'index',
					name: 'index',
					meta: {
						title: '通知公告'
					},
					component: () => import('../views/sharedInstrument/notice.vue')
				},
				{
					path: 'detail',
					name: 'detail',
					meta: {
						title: '公告详情'
					},
					component: () => import('../views/sharedInstrument/articleDetail.vue')
				},
			]
		}
		]
	},
	{
		path: '/about',
		name: 'about',
		meta: {
			title: '关于我们'
		},
		component: Second,
		redirect: '/about/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '关于我们'
			},
			component: () => import('../views/about/index.vue')
		}]
	},
	{
		path: '/technologyFinance',
		name: 'TechnologyFinance',
		component: Second,
		meta: {
			title: '科技金融'
		},
		redirect: '/technologyFinance/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '科技金融'
			},
			component: () => import('../views/technologyFinance/index.vue')
		},
		{
			path: 'loanList',
			name: 'loanList',
			meta: {
				title: '找贷款'
			},
			component: () => import('../views/technologyFinance/loanList.vue')
		},
		{
			path: 'loanDetail',
			name: 'loanDetail',
			meta: { title: '找贷款' },
			component: () => import('../views/technologyFinance/loanDetail.vue')
		},
		{
			path: 'capitalList',
			name: 'capitalList',
			meta: {
				title: '找风投'
			},
			component: () => import('../views/technologyFinance/capitalList.vue')
		},
		{
			path: 'capitalDetail',
			name: 'capitalDetail',
			meta: { title: '找风投' },
			component: () => import('../views/technologyFinance/capitalDetail.vue')
		},
		{
			path: 'insureList',
			name: 'insureList',
			meta: {
				title: '找保险'
			},
			component: () => import('../views/technologyFinance/insureList.vue')
		},
		{
			path: 'insureDetail',
			name: 'insureDetail',
			meta: {
				title: '找保险'
			},
			component: () => import('../views/technologyFinance/insureDetail.vue')
		},
		{
			path: 'activeList',
			name: 'activeList',
			meta: {
				title: '找活动'
			},
			component: () => import('../views/technologyFinance/activeList.vue')
		},
		{
			path: 'activeDetail',
			name: 'activeDetail',
			meta: {
				title: '找活动'
			},
			component: () => import('../views/technologyFinance/activeDetail.vue')
		},
		{
			path: 'projectList',
			name: 'projectList',
			meta: { title: '找项目' },
			component: () => import('../views/technologyFinance/projectList.vue')
		}, {
			path: 'projectDetail',
			name: 'projectDetail',
			meta: { title: '找项目' },
			component: () => import('../views/technologyFinance/projectDetail.vue')
		},
		{
			path: 'policyList',
			name: 'policyList',
			meta: {
				title: '找政策'
			},
			component: () => import('../views/technologyFinance/policyList.vue')
		},
		{
			path: 'policyDetail',
			name: 'policyDetail',
			meta: {
				title: '找政策'
			},
			component: () => import('../views/technologyFinance/policyDetail.vue')
		},
		{
			path: 'perkList',
			name: 'perkList',
			meta: {
				title: '找补贴'
			},
			component: () => import('../views/technologyFinance/perkList.vue')
		},
		{
			path: 'perkDetail',
			name: 'perkDetail',
			meta: { title: '找补贴' },
			component: () => import('../views/technologyFinance/perkDetail.vue')
		},
		{
			path: 'notice',
			name: 'notice',
			meta: {
				title: '通知公告'
			},
			component: () => import('../views/technologyFinance/notice.vue')
		},
		{
			path: 'noticeDetail',
			name: 'noticeDetail',
			meta: {
				title: '通知公告'
			},
			component: () => import('../views/technologyFinance/noticeDetail.vue')
		}

		]
	},
	{
		path: '/intellectualProperty',
		name: 'IntellectualProperty',
		component: Second,
		meta: {
			title: '知识产权'
		},
		redirect: '/intellectualProperty/index',
		children: [{
			path: 'index',
			name: 'index',
			meta: {
				title: '知识产权'
			},
			component: () => import('../views/intellectualProperty/index.vue')
		},
		{
			path: 'search',
			name: 'search',
			meta: {
				title: '搜索'
			},
			component: () => import('../views/intellectualProperty/search.vue')
		},
		{
			path: 'notice',
			name: 'notice',
			meta: {
				title: '通知公告'
			},
			component: () => import('../views/intellectualProperty/notice.vue')
		},
		{
			path: 'noticeDetail',
			name: 'noticeDetail',
			meta: {
				title: '通知公告'
			},
			component: () => import('../views/intellectualProperty/noticeDetail.vue')
		},
		{
			path: 'patentList',
			name: 'patentList',
			meta: {
				title: '专利交易大厅'
			},
			component: () => import('../views/intellectualProperty/patentList.vue')
		},
		{
			path: 'patentDetail',
			name: 'patentDetail',
			meta: {
				title: '专利交易大厅'
			},
			component: () => import('../views/intellectualProperty/patentDetail.vue')
		},
		{
			path: 'knowledgeList',
			name: 'knowledgeList',
			meta: {
				title: '知识产权大厅'
			},
			component: () => import('../views/intellectualProperty/knowledgeList.vue')
		},
		{
			path: 'knowledgeDetail',
			name: 'knowledgeDetail',
			meta: {
				title: '知识产权大厅'
			},
			component: () => import('../views/intellectualProperty/knowledgeDetail.vue')
		},
		{
			path: 'patentDealList',
			name: 'patentDealList',
			meta: { title: '专利交易动态' },
			component: () => import('../views/intellectualProperty/patentDealList.vue')
		},
		{
			path: 'specialList',
			name: 'specialList',
			meta: { title: '专家人才' },
			component: () => import('../views/intellectualProperty/specialList.vue')
		},
		{
			path: 'specialDetail',
			name: 'specialDetail',
			meta: { title: '专家人才' },
			component: () => import('../views/intellectualProperty/specialDetail.vue')
		},
		{
			path: 'organizationList',
			name: 'organizationList',
			meta: { title: '合作机构' },
			component: () => import('../views/intellectualProperty/organizationList.vue')
		},
		{
			path: 'organizationDetail',
			name: 'organizationDetail',
			meta: { title: '合作机构' },
			component: () => import('../views/intellectualProperty/organizationDetail.vue')
		},
		{
			path: 'safeguard',
			name: 'safeguard',
			meta: { title: '维权援助' },
			component: () => import('../views/intellectualProperty/safeguard.vue')
		},
		{
			path: 'safeguardDetail',
			name: 'safeguardDetail',
			meta: { title: '维权援助' },
			component: () => import('../views/intellectualProperty/safeguardDetail.vue')
		},
		{
			path: 'safeguardApply',
			name: 'safeguardApply',
			meta: { title: '维权援助' },
			component: () => import('../views/intellectualProperty/safeguardApply.vue')
		},
		]
	},
	...clouds
	]
},
{
	path: '/boot',
	name: 'BootPage',
	component: () => import('@/views/prePages/boot.vue'),
	hidden: true
},
{
	path: '/logon',
	name: 'Logon',
	component: () => import('@/views/prePages/logon.vue'),
	hidden: true
},
{
	path: '/register',
	name: 'Register',
	component: () => import('@/views/prePages/register.vue'),
	hidden: true
},
]
const scrollBehavior = (to, from, savedPosition) => {
	if (savedPosition) {
		return savedPosition;
	}
	return {
		x: 0,
		y: 0
	};
}
const router = new VueRouter({
	routes,
	// scrollBehavior,
	mode: 'history'
})

export default router
