<template>
  <div class="header-box">
    <div class="left" @click="goVr"></div>
    <div class="top-menu">
      <div class="menu-item" v-for="(nav, index) in menu" :key="index">
        <router-link
          v-if="nav.path != '/technologyExpoCloud' && nav.path != '/technologyMarket'"
          @click.native="checkCurrent(nav)"
          exact
          :to="{ path: nav.path }"
          :class="{ 'nav-item-link': true, 'activeClassA': nav.isActive }"
          active-class="activeClass"
        >
          {{ nav.name
          }}
        </router-link>
        <div
          v-if="nav.path == '/technologyExpoCloud'"
          @click="goKebo"
          style="cursor: pointer;"
        >{{ nav.name }}</div>
        <div
          v-if="nav.path == '/technologyMarket'"
          @click="goTecMarket"
          style="cursor: pointer;"
        >{{ nav.name }}</div>
      </div>
    </div>
    <div class="user">
      <span
        class="link"
        v-if="user_info && (user_info.username || user_info.userName)"
        @click="showCenter"
      >{{ (user_info.username || user_info.userName) }}</span>
      <span class="link" @click="navTo('/logon')" v-else>登录</span>
      <span
        class="link"
        @click="logout"
        v-if="user_info && (user_info.username || user_info.userName)"
      >退出</span>
      <span class="link" @click="navTo('/register')" v-else>注册</span>
    </div>
    <el-dialog
      title="个人中心"
      :visible.sync="showUserCenter"
      :close-on-click-modal="false"
      width="80%"
      :before-close="handleClose"
    >
      <div class="flex-box2">
        <!-- 左侧菜单部分 -->
        <div class="left2">
          <img src="../static/images/huanzhe.png" class="logo" />
          <div class="nav2">
            <div
              :class="{ 'item': true, 'active': activeCode == item.code }"
              v-for="(item, i) in listArr"
              :key="i"
              @click="handleMenuClick(item)"
            >
              <i :class="item.icon"></i>
              <span>{{ item.label }}</span>
            </div>
          </div>
        </div>
        <!-- 右侧主体部分 -->
        <div class="right">
          <!-- 头部包含商户信息人员信息关闭按钮 -->
          <div class="top">
            <div class="business">用户中心</div>
            <div class="user">您好，{{ user_info && user_info.username }}</div>
            <div class="close" @click="handleClose"></div>
          </div>
          <!-- 业务部分 -->
          <div class="professional_work_block">
            <div v-if="activeCode == 1">
              <div class="t3">基本信息</div>
              <el-form label-width="100px" style="width: 500px">
                <el-form-item label="用户名">
                  <el-input v-model="form_userinfo.userName" placeholder="请输入用户名" disabled />
                </el-form-item>
                <el-form-item label="真实姓名">
                  <el-input v-model="form_userinfo.realName" placeholder="请输入真实姓名" />
                </el-form-item>
                <el-form-item label="手机号">
                  <el-input v-model="form_userinfo.phoneNumber" placeholder="请输入手机号" />
                </el-form-item>
                <el-form-item label="邮箱地址">
                  <el-input v-model="form_userinfo.email" placeholder="请输入邮箱地址" />
                </el-form-item>
                <el-form-item label="通讯地址">
                  <el-input v-model="form_userinfo.address" placeholder="通讯地址" />
                </el-form-item>
              </el-form>
              <div style="display: flex;justify-content: center;">
                <el-button type="primary" @click="onSubmitUserInfo" style="width: 150px;">保存</el-button>
              </div>
            </div>
            <div v-if="activeCode == 2">
              <el-tabs v-model="activeName" @tab-click="handleTopClick">
                <el-tab-pane label="仪器共享" name="1">
                  <div class>
                    <el-radio-group v-model="type" size="small" @change="handleSndClick">
                      <el-radio-button label="1">大科学装置</el-radio-button>
                      <el-radio-button label="2">大型仪器</el-radio-button>
                      <el-radio-button label="3">仪器研发</el-radio-button>
                      <el-radio-button label="4">委托开发</el-radio-button>
                    </el-radio-group>
                    <el-table :data="tableData" stripe height="528px" style="width: 100%">
                      <el-table-column prop="instrumentName" label="仪器名称"></el-table-column>
                      <el-table-column prop="contactName" label="姓名"></el-table-column>
                      <el-table-column prop="contactPhone" label="联系方式"></el-table-column>
                      <el-table-column prop="createTime" label="创建时间"></el-table-column>
                    </el-table>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="数字服务" name="2">
                  <el-radio-group v-model="type" size="small" @change="handleSndClick">
                    <el-radio-button label="1">算力服务服务申请</el-radio-button>
                    <el-radio-button label="2">环境试验服务申请</el-radio-button>
                    <el-radio-button label="3">仿真计算服务申请</el-radio-button>
                  </el-radio-group>
                  <el-table :data="tableData" stripe height="528px" style="width: 100%">
                    <el-table-column prop="serviceName" label="服务名称" width="180"></el-table-column>
                    <!-- <el-table-column prop="companyIntroduction" label="公司简介" width="180"></el-table-column> -->
                    <el-table-column prop="contractName" label="姓名"></el-table-column>
                    <el-table-column prop="contractPhone" label="联系方式"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间"></el-table-column>
                  </el-table>
                </el-tab-pane>
                <el-tab-pane label="云上招引" name="3">
                  <el-radio-group v-model="type" size="small" @change="handleSndClick">
                    <el-radio-button label="1">活动报名</el-radio-button>
                    <el-radio-button label="2">入驻申请</el-radio-button>
                  </el-radio-group>
                  <el-table :data="tableData" stripe height="528px" style="width: 100%">
                    <el-table-column prop="companyName" label="公司名称" width="180"></el-table-column>
                    <el-table-column prop="companyIntroduction" label="公司简介" width="180"></el-table-column>
                    <el-table-column prop="contactName" label="姓名"></el-table-column>
                    <el-table-column prop="contactPhone" label="联系方式"></el-table-column>
                    <el-table-column prop="createTime" label="创建时间"></el-table-column>
                  </el-table>
                </el-tab-pane>
              </el-tabs>
            </div>
            <div v-if="activeCode == 3">
              <!-- 修改密码 -->
              <el-form label-width="100px" style="width: 500px">
                <el-form-item label="原密码">
                  <el-input
                    v-model="form_password.oldPassword"
                    placeholder="请输入原密码"
                    type="password"
                  />
                </el-form-item>
                <el-form-item label="新密码">
                  <el-input
                    v-model="form_password.newPassword"
                    placeholder="请输入新密码"
                    type="password"
                  />
                </el-form-item>
                <el-form-item label="确认新密码">
                  <el-input
                    v-model="form_password.newPassword_again"
                    placeholder="请输入新密码"
                    type="password"
                  />
                </el-form-item>
              </el-form>
              <div style="display: flex;justify-content: center;">
                <el-button type="primary" @click="onSubmitPassword" style="width: 150px;">保存</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'header-nav',
  computed: {
    ...mapGetters(["user_info"])
  },
  data() {
    return {
      showUserCenter: false,
      activeCode: '1',
      listArr: [
        {
          icon: 'icon el-icon-user-solid',
          label: '个人信息',
          code: '1'
        },
        {
          icon: 'icon el-icon-s-order',
          label: '我的订单',
          code: '2'
        },
        {
          icon: 'icon el-icon-edit-outline',
          label: '修改密码',
          code: '3'
        }
      ],
      activeName: '1',
      type: '1',
      tableData: [],
      total: 0,
      currentPage: 1,
      form_userinfo: {},
      form_password: {},
      menu: [{
        name: '首页',
        path: '/home'
      },
      {
        name: '科技大市场',
        path: '/technologyMarket'
      },
      {
        name: '仪器共享',
        path: '/sharedInstrument'
      },
      {
        name: '科技金融',
        path: '/technologyFinance'
      },
      {
        name: '知识产权',
        path: '/intellectualProperty'
      },
      {
        name: '数字服务',
        path: '/digitalServices'
      },
      {
        name: '云上招引',
        path: '/solicitationCloud'
      },
      {
        name: '云上科博会',
        path: '/technologyExpoCloud'
      },
      {
        name: '政策大厅',
        path: '/policyHall'
      }
      ]
    }
  },
  watch: {
    '$route'(to, from) {
      this.menu.forEach((m) => {
        if (m.path.length > 1 && to.path && (to.path).indexOf(m.path) > -1) {
          m.isActive = true
        } else {
          m.isActive = false
        }
      })
      this.$forceUpdate();
    }
  },
  created() {
    let path = sessionStorage.getItem("currentRoute")
    this.menu.forEach((m) => {
      if (m.path.length > 1 && path && (path).indexOf(m.path) > -1) {
        m.isActive = true
      } else {
        m.isActive = false
      }
    })
    this.$forceUpdate();
  },
  mounted() {
    console.log('user_innfo = ', this.user_info)
    let user_info = this.user_info || {}
    this.form_userinfo = {
      'userName': user_info.userName,
      'realName': user_info.realName,
      'phoneNumber': user_info.phoneNumber,
      'email': user_info.email,
      'address': user_info.address,
    }
  },
  methods: {
    goVr() {
      window.location.href = "http://case.tusuivr.com/home/preview/view/pano_id/578"
    },
    checkCurrent(nav) {
      sessionStorage.setItem("currentRoute", nav.path)
    },
    goKebo() {
      window.location.href = "http://kbh.mianyang.cn/"
      // window.open('http://kbh.mianyang.cn/', 'yunshang')
    },
    goTecMarket() {
      window.location.href = "https://mycloud-univ.com/tech-achievements"
      // window.open('https://mycloud-univ.com/tech-achievements', 'yunshang')
    },
    navTo(url) {
      this.$router.push(url)
    },
    logout() {
      this.$confirm('您确定要退出登录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        showClose: false,
        closeOnClickModal: false,
        type: 'warning'
      }).then(() => {
        // 退出
        this.$store.commit("updateToken", null)
        this.$store.commit("updateUserInfo", 'clear')
      }).catch(() => {
        // 取消          
      });
    },
    showCenter() {
      this.showUserCenter = true;
      this.activeName = '1';
      this.type = '1';
      this.activeCode = '1';
      this.getTableData()
    },
    handleClose() {
      this.showUserCenter = false;
    },
    handleMenuClick(item) {
      // 激活样式
      this.activeCode = item.code
      // 去处理一些其他逻辑
    },
    handleTopClick(tab, event) {
      this.type = 1;
      this.currentPage = 1;
      this.getTableData()
    },
    handleSndClick() {
      this.currentPage = 1;
      this.getTableData()
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getTableData()
    },
    getTableData() {
      let params = {
        modelType: this.activeName,
        type: this.type,
        // pageSize: 10,
        // pageNum: this.currentPage
      }
      this.$api.common.order(params).then((res) => {
        this.tableData = res.data.data
        console.log('res.data.data = ', res.data.data)
      })
    },
    onSubmitUserInfo() {
      // if (!this.form_userinfo.nickName) {
      //   this.$message.error('请输入昵称');
      //   return
      // }
      // if (!this.form_userinfo.userName) {
      //   this.$message.error('请输入用户名');
      //   return
      // }
      var params = {
        ...this.form_userinfo,
        // 'id': this.article_id
      }
      delete params.userName

      this.$api.common.userUpdate(params).then((res) => {
        const code = res.data.code
        if (code == 200) {
          this.$message({
            message: '提交成功',
            type: 'success'
          });
        }
      }).catch(() => {
      })
    },
    onSubmitPassword() {
      if (!this.form_password.oldPassword) {
        this.$message.error('请输入旧密码');
        return
      }

      if (!this.form_password.newPassword || !this.form_password.newPassword_again) {
        this.$message.error('请输入新密码');
        return
      }
      if (this.form_password.newPassword != this.form_password.newPassword_again) {
        this.$message.error('两次输入密码不一致');
        return
      }
      const params = {
        ...this.form_password,
        'id': this.user_info.id
      }
      console.log('this.userinfo = ', this.user_info)

      this.$api.common.userPassword(params).then((res) => {
        const code = res.data.code
        if (code == 200) {
          this.$message({
            message: '提交成功',
            type: 'success'
          });
        }
      }).catch(() => {
      })
    },
  }
}
</script>
<style scoped lang="less">
/deep/.el-dialog {
  width: 80%;
  min-width: 800px;
  max-width: 1100px;
  border-radius: 5px;
}

/deep/.el-dialog__header {
  display: none;
}

/deep/.el-dialog__body {
  padding: 0 !important;
}

.flex-box2 {
  display: flex;
  height: 693px;

  .left2 {
    width: 150px;
    background-color: #f6f6f6;
    color: #fff;
    height: 100%;
    box-sizing: border-box;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

    .logo {
      width: 58px;
      height: auto;
      display: block;
      margin: 48px auto 5px;
      border: 2px solid #cfd0d2;
      border-radius: 50%;
    }

    .name {
      text-align: center;
      margin-bottom: 48px;
    }

    .nav2 {
      margin-top: 40px;

      .item {
        padding: 16px 0 16px 30px;
        display: flex;
        align-items: center;
        font-size: 16px;
        cursor: pointer;
        color: #333;

        .icon {
          // width: 22px;
          // height: 22px;
          margin-right: 10px;
        }

        &:hover {
          font-weight: bolder;
          border-right: #06bea3 4px solid;
          color: #06bea3;
        }
      }

      .item.active {
        position: relative;
        border-right: #06bea3 4px solid;
        color: #06bea3;
        background-color: #e9f7f5;

        &::after {
          position: absolute;
          content: "";
          background-color: #fff;
          top: 0;
          bottom: 0;
          left: 0;
          width: 4px;
          border-radius: 3px;
        }
      }
    }
  }

  .right {
    color: #333;
    position: relative;

    padding: 60px 10px 10px;
    flex: 1;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;

    .top {
      background: #fff;
      position: absolute;
      border-top-right-radius: 10px;
      top: 0;
      left: 0;
      right: 0;
      height: 50px;
      display: flex;
      padding-right: 80px;
      padding-left: 20px;
      font-size: 16px;
      color: #000;
      line-height: 50px;
      justify-content: space-between;

      .business {
        font-weight: bolder;
      }

      .user {
        margin-right: 80px;
      }

      .close {
        position: absolute;
        top: 17px;
        right: 17px;
        width: 16px;
        height: 16px;
        cursor: pointer;
        background-image: url("../static/images/5888@2x.png");
        background-size: 100% 100%;
      }
    }

    .t3 {
      font-size: 16px;
      font-weight: 700;
      margin-left: 10px;
      border-left: 4px solid #06bea3;
      padding-left: 20px;
      margin-bottom: 20px;
    }
  }
}

.header-box {
  color: #fff;
  min-width: 1280px;
  max-width: 1840px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
  padding: 20px 10px 20px 10px;
  box-sizing: border-box;
  // background-image: url('../static/images/left.jpeg');
  background-image: url("../static/header-bg.png");
  background-repeat: no-repeat;
  background-size: auto 215px !important;
  background-position: -39px -34px;

  .left {
    display: flex;
    float: left;
    align-items: center;
    cursor: pointer;
    width: 320px;
    height: 74px;
  }

  .top-menu {
    margin-left: 340px;
    margin-top: 15px;
    display: flex;
    justify-content: space-around;
    border-radius: 34px;
    padding: 0 10px;
    box-shadow: inset 0 0 34px rgba(255, 255, 255, 0.3);

    a.nav-item-link {
      color: #fff;
    }

    a.nav-item-link:hover {
      color: #00ffff;
    }

    a.activeClass {
      color: #00ffff;
    }

    a.activeClassA {
      color: #00ffff;
    }

    .menu-item {
      font-size: 20px;
      line-height: 60px;
    }
  }

  .user {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 16px;

    .link {
      cursor: pointer;
      padding: 0 15px;
      line-height: 2.4;
      border-right: 1px solid #fff;
    }

    .link:hover {
      font-weight: 700;
    }

    .link:last-child {
      border: none;
    }
  }
}
</style>

