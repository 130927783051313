import md5 from './md5.js'
import axios from 'axios'
import config from '@config'
import storage from '@/utils/storage.js'
import {
	Message,
	MessageBox
} from 'element-ui'
import router from '@/router'
import store from '@/store'
import api from '@service/_'

class Utils {
	static testName() {
		return "utils.js/testName(): 静态方法的测试";
	}
	//加密参数
	static signParam(param) {
		var sortstr = ''
		const sortKeys = Object.keys(param).sort()
		sortKeys.map((v) => {
			// sortParam[v] = param[v]
			if (param[v] != null) {
				sortstr = sortstr + `${v}=${param[v]}&`
			}
		})
		sortstr = sortstr + `key=c7e8b687c5cd!@#mafa`
		const md5Str = md5(sortstr)
		return md5Str
	}
	
	static check_is_Login = () => {
		if (!storage.getToken()) {
			router.push({
				path: '/login'
			})
			return;
		}
	}

}

export default Utils;
