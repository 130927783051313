import Vant from 'vant'
import 'vant/lib/index.css'
import service from '@service/_.js'
import utils from '@utils/index.js'

export default {
	DevUrl: '/api',
	ProdUrl: '/api',
	check: function() {
		if (process.env.NODE_ENV == "development") {
			return this.DevUrl
		} else {
			return this.ProdUrl
		}
	},
	ApiUrl: {
		index: '/index'
	},
	VuePlug: [Vant],
	CustomPlug: [{
			n: '$api',
			v: service
		},
		{
			n: '$util',
			v: utils
		}
	]
}
