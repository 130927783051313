import http from '@http'
let request = http.getInstance()
class IntellectualProperty {
	async index() { //api/property/pc/index
		return await request.get("/property/pc/index")
	}
	// 动态政策列表 /api/property/pc/policy
	async policyList(param) {
		return await request.get("/property/pc/policy", param)
	}
	// 动态政策详情
	async policyDetail(id) {
		return await request.get(`/property/pc/policy/detail/${id}`)
	}
	//专利交易大厅 /api/property/pc/patent
	async patentList(param) {
		return await request.get("/property/pc/patent", param)
	}
	// 详情
	async patentDetail(id) {
		return await request.get(`/property/pc/patent/detail/${id}`)
	}
	//知识产权大厅 /api/property/pc/info
	async infoList(param) {
		return await request.get("/property/pc/info", param)
	}
	// 详情
	async infoDetail(id) {
		return await request.get(`/property/pc/info/detail/${id}`)
	}
	//专利交易动态 /api/property/pc/trade
	async tradeList(param) {
		return await request.get("/property/pc/trade", param)
	}
	// 详情
	async tradeDetail(id) {
		return await request.get(`/property/pc/trade/detail/${id}`)
	}
	//专家人才 /api/property/pc/expert
	async expertList(param) {
		return await request.get("/property/pc/expert", param)
	}
	// 详情
	async expertDetail(id) {
		return await request.get(`/property/pc/expert/detail/${id}`)
	}
	//合作机构 /api/property/pc/giz
	async gizList(param) {
		return await request.get("/property/pc/giz", param)
	}
	// 详情
	async gizDetail(id) {
		return await request.get(`/property/pc/giz/detail/${id}`)
	}
	//搜索 /api/property/pc/search
	async searchList(param) {
		return await request.get("/property/pc/search", param)
	}
	//维权实例列表 /api/property/pc/aid/list
	async aidList(param) {
		return await request.get("/property/pc/aid/list", param)
	}
	// 详情/property/pc/aid/detail/{id}
	async aidDetail(id) {
		return await request.get(`/property/pc/aid/detail/${id}`)
	}
	//维权申请 /api/property/pc/apply
	async aidApply(param) {
		return await request.post("/property/pc/apply", param)
	}
}
export default new IntellectualProperty()
