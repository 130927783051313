<template>
  <!-- <div >
    <div>
      <router-view />
    </div> 
  </div>-->
  <div class="inside-page-content">
    <div class="paper light-grey-bg">
      <router-view />
    </div>
  </div>
</template>
<script>
import Bread from '../../components/bread.vue'
export default {
  name: 'Second',
  components: {
    Bread
  },
  data() {
    return {
      showBread: false
    }
  },
  computed: {

  },
  watch: {
    $route(to, from) {
      let r = to.matched
      this.showBread = r.length > 2 && r[1].redirect != r[2].path ? true : false
    }
  },
  created() {
    let r = this.$route.matched;
    this.showBread = r.length > 2 && r[1].redirect != r[2].path ? true : false
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
.app {
  min-width: 1280px;
  // background-color: #0031BD;
  background-color: #333;
  min-height: 100vh;
  height: auto;
  // background-image: url('../../static/images/bg.png');
  background-size: 100% auto;
  background-position: 0 0;
}
</style>