import 'amfe-flexible'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import config from './config';
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Print from '@/utils/newPrint'
require('./style/common.less')

// 配置vue插件
config.VuePlug.forEach((v)=> Vue.use(v));
config.CustomPlug.forEach((v) => Vue.prototype[v.n] = v.v);
Vue.use(ElementUI)
Vue.use(Print)
import i18n from './i18n/index.js'
Vue.config.productionTip = false
Vue.prototype.$EventBus = new Vue()
Vue.filter("numberFixed", function(x, y = 4) {
	return Number(x).toFixed(y)
})
const app =  new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
})
app.$mount('#app')
export default app
