/**
 * 英语
 */
export default {
  // 菜单路由
  route: {
    home: '首页',
    notice: '公告'
  },
  // 主框架
  layout: {
    
  },
  // 基础列表
  list: {
    
  }
};
